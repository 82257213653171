<template>
  <div class="login">
    
    <div class="head">
      <div class="login-title">
       游客
      </div>
      <div class="login-subtitle">高效管理每个旅游从业者</div>
    </div>
    <!--登陆-->
    <div class="form">
      <div class="item">
        <input
          type="text"
          name="name"
          maxlength="11"
          placeholder="请输入您的帐号"
          autocomplete="off"
          class="name"
          v-model="user.mobile"
        />
      </div>
      <div class="item" v-if="type==1">
        <input
          type="text"
          name="vcode"
          maxlength="6"
          placeholder="请输入验证码"
          autocomplete="off"
          v-model="user.code"
          class="vcode"
        />
        <span id="vcode" v-if="!disabled" @click="validateBtn" style="margin-left:10px"
          :class="vcodeDisabled? 'disabled-bg': ''">{{
          btnTitle
        }}</span>
        <span id="vcode" v-if="disabled" class="disabled-bg" style="margin-left:10px">{{ btnTitle }}</span>
      </div>
      <div class="item" v-if="type==2">
        <input
          type="password"
          name="password"
          placeholder="请输入登录密码"
          autocomplete="off"
          class="password"
          v-model="user.password"
        />
      </div>
      <!-- <p>忘记密码</p> -->
      
      <div class="submit vcode"><button @click="do_sign_in" :disabled="!loginBtnClickable"
        :style="{'background': loginBtnClickable? '#6c94f6': ''}">
          登&nbsp;录
        </button>
      </div>
      <div class="login-method" v-if="type == 2" @click="switchType(1)">
        验证码登录
      </div>
      <div class="login-method" v-if="type == 1" @click="switchType(2)">
        账号密码登录
      </div>
    </div>
    <!--第三方登陆-->
    <!-- <div class="other-login">
      <p><span>使用第三方帐号登陆</span></p>
      <div class="third">
        <ul>
          <li><img src="../../assets/picture/icon-qq.png" /></li>
          <li><img src="../../assets/picture/icon-weixin.png" /></li>
          <li><img src="../../assets/picture/icon-weibo.png" /></li>
        </ul>
      </div>
    </div> -->
    <!--尾部-->
    <div class="footer">
      <!-- <img src="../../assets/picture/logo.png" /> -->
      <p>
        登录使用就表示同意用户协议<a href="javascript:;" @click="onAgreementClick">条款和隐私</a
        >政策
      </p>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import cookies from "../../utils/cookies";
export default {
  name: "Login",
  components: {},
  data() {
    return {
      mobile: "", //手机号
      verifyCode: "", //验证码
      password: "",
      btnTitle: "获取验证码",
      disabled: false, //是否可点击
      errors: {}, //验证提示信息
      user: {
        mobile: cookies.get("mobile")
          ? cookies.get("mobile")
          : "" ,
        password: "",
        code : ""
      },
      type:1
    };
  },
  computed: {
    loginBtnClickable() {
      return (this.user.password != '' || this.user.code != '') && this.user.mobile != ''
    },
    vcodeDisabled() {
      return !/^1[345678]\d{9}$/.test(this.user.mobile)
    }
  },
  methods: {
    onAgreementClick(){
      this.$router.push('/agreement')
    },
    onPrivacyClick(){
      this.$router.push('/privacy')
    },
    async do_sign_in() {
      if (this.validatemobile() && (this.user.password != '' || 
        this.user.code != '')) {
        const _self = this;
        const user = {
          user: _self.user,
        };
        const res = await this.$apis.sing_in(_self.user);
        if(res.access_token) {
          cookies.set('token',res.access_token)
          Toast.success('登录成功!')
          this.$store.dispatch('wode/getPersonInfo')
          this.$store.dispatch('travel_list/getAllTravelLists', 1)
          this.$router.push("/")
        } else {
          Toast(res.message)
        }
      }
    },
    switchType(type) {
      this.type = type
      this.user.password = ''
      this.user.code = ''
    },
    validateBtn() {
      let _self = this
      if(this.validatemobile()) {
        this.$apis.send_sms(_self.user.mobile).then((res) => {
          if(res.status === 200) {
            Toast('验证码已发送到您的手机，请注意查收！')
            this.show = false;
            let time = 60;
            let timer = setInterval(() => {
              if (time == 0) {
                clearInterval(timer);
                this.disabled = false;
                this.btnTitle = "获取验证码";
              } else {
                this.btnTitle = time + "秒后重试";
                this.disabled = true;
                time--;
              }
            }, 1000);
          }
          //倒计时
        })
      }
    },
    validatemobile() {
      //判断输入的手机号是否合法
      if (!this.user.mobile) {
        Toast("请输入手机号");
        return false;
      } else if (!/^1[345678]\d{9}$/.test(this.user.mobile)) {
        Toast("请输入正确的手机号!");
        return false;
      } else {
        this.errors = {};
        return true;
      }
    },
  },
};
</script>
<style scoped>
@import "./style.css";
.login-method {
    font-size: .3rem;
    /* margin-top: 10px; */
    color: rgba(255, 255, 255, 0.61);
    /* width: 2rem; */
    display: inline;
}
</style>